import HOC from './HOC';
import Title from './Title';
import VideoElem from './VideoElem';
import ytButton from '../images/youtube-button.jpg';
import './Videos.css';

export default function Videos({}) {
  return (
    <div className='Videos'>
      <HOC>
        <div className='videos-contain'>
          <Title>
            <h1>Sample Videos</h1>
          </Title>
          <div className='vid-block'>
            <VideoElem title='Big Book History #1: The First Two Forewords' link='https://www.youtube.com/embed/4Vp8CoeNNy0?si=a1J5lGQ5zQ1swnNQ' id='bbh1'/>
            <VideoElem title="Big Book History #2: The Doctor's Opinion" link='https://www.youtube.com/embed/PDcGBgCofTg?si=gOhY6RzwMLHYim4G' id='bbh2'/>
          </div>
          {/* <VideoElem title="Big Book History #3: Bill's Story" link='https://www.youtube.com/embed/g1BnY6k_6MA?si=yqf5q8ZhFSj6awHL' id='bbh3'/> */}

          <div className='yt-blurb'>
            <p>Many more videos available on the Bill Schaberg YouTube Channel!</p>
            <a href='https://www.youtube.com/@billschaberg8911' target='_blank'><img src={ytButton} alt='yt-btn' /></a>
          </div>
        </div>
      </HOC>
    </div>
  )
}