import './AboutTheAuthor.css'
import HOC from './HOC'
import Title from './Title'
import headshot from '../images/bill-headshot.jpg'

const AboutText = () => {
  return (
    <div className='about-text'>
      <p>Bill Schaberg is the proprietor of Athena Rare Books, a firm specializing in first edition philosophy books published between the 16th and the 20th centuries.</p>
      <p>His first book, The Nietzsche Canon: A Publication History and Bibliography, was published by the University of Chicago Press in 1995 and seven years later it was translated into German.</p>
      <p>Deeply committed to the study of philosophy since college, Schaberg finally had to admit he was never going to find the “answers to life” that he was so diligently searching for within that discipline.</p>
      <p>While the ancient philosophers (those original “lovers of wisdom”) were primarily trying to discover the best ways for us to live our lives, modern philosophy has veered off into several different specialties (theories of knowledge, language analysis, etc.) that add absolutely nothing to our understanding of how to go about “living well”.</p>
      <p>It took more than a few years, but Schaberg finally realized that those answers are best found in the experience of others – experiences that have been candidly shared and then attentively heard, accepted and acted upon.</p>
    </div>
  )
}

export default function AboutTheAuthor({children}) {
    return (
      <div className='AboutTheAuthor'>
        <HOC>
          <div className='about-contain'>
            <Title>
              <h1>About The Author</h1>
            </Title>

            <AboutText />
            <img src={headshot} alt='headshot' />
          </div>
        </HOC>
      </div>
    )
}