import './NavBar.css'
import { Link } from 'react-router-dom'

const NavButton = ({label, link}) => {
    return (
      <div className='nav-contain'>
        <Link to={link}>
          <div className={`navButton-hidden ${window.location.pathname === link ? 'shown' : ''}`}>
            <span>{label}</span>
          </div>
        </Link>
        <div className='navButton'>
          <span>{label}</span>
        </div>
      </div>
    )
  }

export default function NavBar() {
    return (
        <div className="NavBar">
          <NavButton label="Home" link="/" />
          <NavButton label="About The Author" link="/author" />
          <NavButton label="Other Books" link="/other-books" />
          {/* <NavButton label="Appearances" link="/appearances" /> */}
          <NavButton label="Videos" link="/videos" />
          <NavButton label="Contact Bill" link="/contact-bill" />
        </div>
    )
}