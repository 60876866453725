import './HOC.css'
import Footer from './Footer'
import Header from './Header'
import NavBar from './NavBar'
import { useEffect, useState } from 'react'

export default function HOC({children}) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    window.onresize = () => setWindowWidth(window.innerWidth)

    return (
      <div className='HOC' style={windowWidth < 1100 ? {zoom: windowWidth / 1100} : {}}>
        <Header />
        <NavBar />
        <div className='hoc-children' >{children}</div>
        <Footer />
      </div>
    )
}