import './Contact.css';
import HOC from './HOC';
import Title from './Title';
import headshot from '../images/bill-headshot.jpg'

export default function Contact({}) {
  return (
    <div className='Contact'>
      <HOC>
        <div className='contact-contain'>
          <Title>
            <h1>Contact the Author</h1>
          </Title>

          <img src={headshot} alt='headshot' />

          <div className='subcontacts'>
            <div className='subc'>
              <span>Email: </span>
              <span>bill@athenararebooks.com</span>
            </div>
            <p>Please include “30 Things” in the subject line of your email to avoid getting lost in the dreaded Spam Folder</p>
          </div>
        </div>
      </HOC>
    </div>
  )
}