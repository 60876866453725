import HOC from './HOC'
import './OtherBooks.css'
import Title from './Title'
import nietzscheCanon from '../images/Nietzsche-Canon-web.jpg'
import nietzscheWerke from '../images/Nietzsches-Werke-web.jpg'
import stepCover from '../images/The-Big-Book-Step-Guide-Cover-V6.jpg'
import bigBookCover from '../images/writing-big-book-book-cover.jpg'
import pdfIcon from '../images/PDF_icon.svg';

const BookDisplay = ({title, blurb, pubInfo, photoURI}) => {
  return (
    <div className='BookDisplay'>
      <h3>{title}</h3>
      <p className='blurb'>{blurb}</p>
      <p className='pubInfo'>{pubInfo}</p>
      <img src={photoURI} alt='other-book' />
    </div>
  )
}

export default function OtherBooks() {
  return (
    <div className='OtherBooks'>
      <HOC>
        <div className='ob-contain'>
          <Title>
            <h1>Other Books by William H. Schaberg</h1>
          </Title>
          <BookDisplay title='The Nietzsche Canon' blurb='A Publication History and Bibliography' pubInfo='The University of Chicago Press, 1995' photoURI={nietzscheCanon}/>
          <div className='book-desc'>
            <p className='subt'>(from the rear dust jacket)</p>
            <h4>Praise for The Nietzsche Canon:</h4>
            
            <p>“The Nietzsche Canon offers a fascinating picture of Nietzsche as an author dealing with his publishers – and in the context of a very different publishing world from today’s. In view of the sometimes obsessive care with which Nietzsche tried to guide his works into print, one has to respect the distinction – so often ignored – between his published and unpublished writings. Schaberg’s account conveys a vivid sense of the effect on Nietzsche of the lamentably meager sales during his lifetime of the books he wrote.”</p>
            <p className='author'><b>Graham Parkes</b>, University of Hawaii</p>

            <p>“This book is a slice of Nietzsche biography that is broad and deep. It should appeal to everyone with an interest in Nietzsche.”</p>
            <p className='author'><b>Laurence Lampert</b>, Indiana University</p>
          </div>
          <div className='hr'></div>

          <BookDisplay title='Nietzsches Werke' blurb='Eine Publikationsgeschichte und kommentierte Bibliographie' pubInfo='Schwabe & Co. AG, Basel, 2002' photoURI={nietzscheWerke}/>
          <div className='book-desc'>
            <p><i>While the praise above is welcome, the most important “review” of this book was the fact that German scholars insisted it be translated and published in German.</i></p>
          </div>
          <div className='hr'></div>

          <BookDisplay title='Writing the Big Book' blurb='' pubInfo='' photoURI={bigBookCover}/>
          <div className='book-desc'>
            <p>Writing the Big Book presents a robust and vivid picture of how early A.A. operated and grew along with a vast amount of previously unreported details about the cast of colorful characters who made that group so successful. Most surprising is the emergence of Bill Wilson’s right-hand man, Hank Parkhurst as the unsung hero in this story. Without Hank there would have been no book, but his unfortunate slip back into drinking just months after it was published resulted in him being almost completely written out of the stories that were told later.</p>
          </div>
          <div className='hr'></div>

          <BookDisplay title='A Step Work Group Guide' blurb='' pubInfo='' photoURI={stepCover}/>
          <div className='book-desc'>
            <div className='download-contain'>
              <p>This Step Work Group Guide has been used with great success since 2003, earning lavish praise from some of the most active and respected people in Alcoholics Anonymous:   “This process proved to be so much more useful and comprehensive than anything we have ever done that we have adopted it as our regular format.” (Bill C., Torrance, CA)   “The Guide helped us immensely in really getting at the issues and in understanding the solution.”  (Nancy D. Morro Bay, CA)   “This Guide has turned out to be the most powerful experience in guiding me towards the revolutionary spiritual awakening that the founders speak about in the Big Book.” (Stuart W., Fairfield, CT)   “This book provides people with a clear path to a transformative experience.” (Jay S., Sedona, AZ)   “We were all surprised how deep the 18 weeks were and how we bonded in a way that was simply beautiful. This workbook took my sobriety to a whole new level.” (Hilary M., Los Angeles, CA)</p>
            </div>
          </div>
        </div>
      </HOC>
    </div>
  )
}