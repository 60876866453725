import './Appearances.css';
import HOC from './HOC';
import MonthlyCalendar from './MonthlyCalendar';
import Title from './Title';

export default function Appearances({}) {
  return (
    <div className='Appearances'>
      <HOC>
        <div className='appearances-contain'>
          <Title>
            <h1>Appearances</h1>
          </Title>

          <MonthlyCalendar />

        </div>
      </HOC>
    </div>
  )
}