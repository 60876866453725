import './Reviews.css'
import HOC from './HOC'
import Title from './Title'
import cross from '../images/cross.svg'
import { useState } from 'react'

function disableScroll() {
    document.body.classList.add('noscroll')
  }
  
function enableScroll() {
    document.body.classList.remove('noscroll')
}


const ReviewFull = ({closer, children}) => {
  return (
    <div className='ReviewFull'>
      <div className='hrow'>
        <h3>Review</h3>
        <img src={cross} alt='cross' onClick={() => closer()} />
      </div>
      
      <div className='rauth'>
        <h4>30 Things: Practical Advice for Living Well</h4>
        <p className='rauth'>by William Schaberg</p>
      </div>

      <div className='rbody'>
        {children}
      </div>

      <div className='frow'>
        <div className='close-btn' onClick={() => closer()}>Close</div>
      </div>
    </div>
  )
}

const ReviewShort = ({blurb, reviewer, authDesc, full}) => {
  const [reviewShown, setReviewShown] = useState(false)
  const [fading, setFading] = useState(false)
  const onClickReview = () => {
    console.log(reviewShown)
    setReviewShown(true)
    disableScroll()
  }

  const closePopup = () => {
    console.log(reviewShown)
    setFading(true)
    setTimeout(() => {
      setFading(false)
      setReviewShown(false)
    }, 150)
    enableScroll()
  }

  return (
    <div className='Review'>
      <div className='review-content'>
        <p className='review-blurb'>{blurb}</p>
        <p className='reviewer'>{reviewer}</p>
        <div>{
          authDesc.map(desc => <p className='auth-desc'>{desc}</p>)
        }</div>
        <p className='review-clicker' onClick={onClickReview}>[Click here to read the full review]</p>
      </div>
      <hr/>
      <div className={`bkgd-blocker ${reviewShown ? 'show-blocker' : ''} ${fading ? 'fade' : ''}`}>
        <ReviewFull closer={closePopup}>{full}</ReviewFull>
      </div>
    </div>
  )
} 

export default function Reviews({children}) {

    const reviews = [
      {
        blurb: <div>
                  <p>There are many things to like about 30 Things, starting with the fact that it is easy and enjoyable to read, thanks to the author&#39;s writing skills…</p>
                  <p>Next – and perhaps most importantly – it is written from a position of humility rather than authority, which makes the advice offered easy to digest. This stands in contrast to the patronizing approach that typically characterizes &quot;advice&quot; writings.</p>
                  <p>…I heartily recommend 30 Things to all who are open to seeing life from a different perspective.</p>
                </div>,
        reviewer: <span>Joe Nowinski, PhD</span>,
        authDesc: [
          <p>Internationally recognized clinical psychologist and author, currently an associate professor of psychology at UConn and assistant professor at the Hazelden Betty Ford Graduate School</p>,
        ],
        full: 
              <>
                <h4>Reviewed by Joe Nowinski</h4>
                <p>There are many things to like about 30 Things, starting with the fact that it is easy and enjoyable to read, thanks to the author's writing skills. It is comfortable to read in chunks of 3-4 "things" and then pause to reflect on them as they may apply to the reader.</p>
                <p>Next—and perhaps most importantly—it is written from a position of humility rather than authority, which makes the advice offered easy to digest. This stands in contrast to the patronizing approach that typically characterizes "advice" writings.</p>
                <p>Most of the "things", in fact, emanate from the author's own experiences and originate with others he respects. While it is doubtful that I or other readers will be able to memorize these 30 things and apply them on a daily basis, I can easily see how a situation I find myself in may stimulate my memory of one "thing" or another, and thereby help to inform my actions.</p>
                <p>For these reasons, I heartily recommend 30 Things to all who are open to seeing life from a different perspective.</p>
                <div className='reviewer-info'>
                  <h4>Reviewed by Joe Nowinski</h4>
                  <p><i>Joe Nowinski, PhD (Tolland, Connecticut), is an internationally recognized clinical psychologist and author. He is an associate professor of psychology at the University of Connecticut and an assistant professor at the Hazelden Betty Ford Graduate School. His past positions include assistant professor of psychiatry at the University of California</i></p>
                </div>
              </>
      },
      {
        blurb: <div>
                  <p>As a licensed therapist specializing in substance abuse and erasing shame, I found 30 Things by William Schaberg to be a valuable source of wise and practical advice. This book provides an array of insights that are especially beneficial for therapists and their clients on a journey toward healing and self-improvement…</p>
                  <p>30 Things offers practical lessons that can significantly benefit therapeutic practices. Schaberg’s insights offer clear steps for living a more balanced and fulfilling life. I recommend this book for both therapists and clients looking to overcome challenges and to grow personally.</p>
                </div>,
        reviewer: <span>Adina Silvestri, EdD, LPC</span>,
        authDesc: [
          <p>Licensed Professional Counselor</p>,
          <p>Certified Clinical Hypnotherapist</p>,
          <p>Certified Brainspotting Practitioner</p>,
        ],
        full:
              <>
                <h4>Reviewed by Adina Silvestri</h4>
                <p>As a licensed therapist specializing in substance abuse and erasing shame, I found 30 Things: Practical Advice for Living Well by William Schaberg to be a valuable source of wise and practical advice. This book provides an array of insights that are especially beneficial for therapists and their clients on a journey toward healing and self- improvement. Below are some key highlights and how they can be applied to therapy:</p>
                <div className='ol'>
                  <h4>1. Loving Someone Means Acting as If Their Happiness Is Important to You</h4>
                  <li>Schaberg emphasizes that loving actions are integral to building and maintaining relationships. This aligns with therapeutic practices that encourage clients to express love and care through tangible actions, thereby strengthening their connections with loved ones.</li>
                  <li><b>Example:</b> A therapist might encourage a client to perform a small, thoughtful act for a family member, reinforcing the idea that love is demonstrated through actions, not just words.</li>
                  <h4>2. Embrace the Fact That We Are All Different</h4>
                  <li>Schaberg’s advice to acknowledge and appreciate individual differences is crucial in therapy. This helps clients understand that differing perspectives and behaviors are normal and can be embraced rather than judged.</li>
                  <li><b>Application:</b> Encourage clients to explore and celebrate their unique traits and those of others, fostering acceptance and reducing interpersonal conflicts.</li>
                  <h4>3. Become a Morning Person</h4>
                  <li>Schaberg’s advocacy for morning productivity can be an effective strategy for clients struggling with time management and motivation. The quiet morning hours can be a great time for reflection, planning, and self-care activities.</li>
                  <li><b>Implementation:</b> Suggest clients create a morning routine that includes
                  journaling, meditation, or exercise to start their day on a positive note.</li>
                  <h4>4. You Can Pretend to Care, But You Can’t Pretend to Be There</h4>
                  <li>The importance of presence in relationships is highlighted by Schaberg, which is a vital concept in therapy. Being fully present with others builds trust and deepens emotional connections.</li>
                  <li><b>Practice:</b> Encourage clients to practice mindfulness in their interactions, focusing on truly being there for their loved ones without distractions.</li>
                  <h4>5. Identify the Places Where You Encounter the Spiritual and Go There Often</h4>
                  <li>Finding and regularly visiting places that evoke a sense of spirituality or peace can be incredibly healing for clients. This advice promotes self-care and the importance of environments in emotional well-being.</li>
                  <li><b>Strategy:</b> Help clients identify their personal sanctuaries, whether physical places or mental states, and incorporate regular visits to these spaces into their routines. And who doesn’t want to understand the subconscious better.</li>
                </div>
                <p>In conclusion, <i>30 Things: Practical Advice for Living Well</i> offers practical lessons that can significantly benefit therapeutic practices. Schaberg’s insights offer clear steps for living a more balanced and fulfilling life. I recommend this book for both therapists and clients looking to overcome challenges and grow personally.</p>
                <div className='reviewer-info'>
                  <h4>Adina Silvestri, EdD, LPC</h4>
                  <p>Licensed Professional Counselor</p>
                  <p>Certified Clinical Hypnotherapist</p>
                  <p>Certified Brainspotting Practitioner</p>
                </div>
              </>
      }
    ]
    return (
      <div className='AboutTheAuthor'>
        <HOC>
          <div className='review-contain'>
            <Title>
              <h1>Pre-Publication Reader's Reviews</h1>
            </Title>

            {reviews.map(r => <ReviewShort blurb={r.blurb} reviewer={r.reviewer} authDesc={r.authDesc} full={r.full}/>)}
          </div>
        </HOC>
      </div>
    )
}