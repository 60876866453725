import './App.css';
import AboutTheAuthor from './components/AboutTheAuthor';
import Home from './components/Home'
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import OtherBooks from './components/OtherBooks';
import Appearances from './components/Appearances';
import Videos from './components/Videos';
import Contact from './components/Contact';
import { useEffect, useMemo } from 'react';
import Reviews from './components/Reviews';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<Home />} replace/>
          
          <Route path="author" element={<AboutTheAuthor />}/>
          <Route path="other-books" element={<OtherBooks />}/>
          <Route path="appearances" element={<Appearances />}/>
          <Route path="videos" element={<Videos />}/>
          <Route path="contact-bill" element={<Contact />}/>
          <Route path="reviews" element={<Reviews />}/>
          <Route path="*" element={<Navigate to='/' />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
